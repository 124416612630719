<template>
	<div>
		<!-- <s-select-definition hidden @change="changeLayette($event)" label="Nro Canastilla" :def="1259"  /> -->

		<v-container>
			<v-tabs v-model="currentItem">
				<v-tab href="#Traceability"> Trazabilidad</v-tab>
				<v-tab href="#QR"> Leer Codigo QR</v-tab>
			</v-tabs>

			<v-tabs-items v-model="currentItem">
				<v-tab-item :value="'Traceability'"> 
                    <prf-traceability-eur />
                </v-tab-item>

				<!-- <v-tab-item :value="'QR'"> 
                    <packing-scann-q-r />
                </v-tab-item> -->
			</v-tabs-items>
		</v-container>
	</div>
</template>
<script>
	//Component
	import PrfTraceabilityEur from "./PrfTraceabilityEur.vue";
    import PackingScannQR from "./PackingScannQR.vue";

	export default {
		components: { PrfTraceabilityEur, PackingScannQR},
		data: () => ({
			currentItem: "tab-Funciones",
		}),
	};
</script>
