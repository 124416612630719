<template>
	<div>
		<s-crud
			title="Trazabilidad EUROPA"
			:filter="filter"
			:config="config"
			search-input
			no-full
			@rowSelected="rowSelected($event)"
			height="auto"
		>
			<template v-slot:filter>
				<v-container>
					<v-row style="margin-left: 5px" justify="center">
						<v-col cols="6" lg="3">
							<s-date v-model="filter.cDateInitial" label="Fecha Inicio">
							</s-date>
						</v-col>
						<v-col cols="6" lg="3">
							<s-date v-model="filter.cDateFinal" label="Fecha Fin">
							</s-date>
						</v-col>
					</v-row>
				</v-container>
			</template>
		</s-crud>

		<v-btn @click="showTraceability = true" color="info">Reporte</v-btn>

		<v-dialog width="1000" v-model="showTraceability" v-if="showTraceability">
			<prf-traceability-view :selected="selected" @close="showTraceability = false"></prf-traceability-view>
		</v-dialog>
	</div>
</template>

<script>
	import sTraceabilityService from "../../../services/FreshProduction/PrfTraceabilityService.js";
	import PrfTraceabilityView from './PrfTraceabilityView.vue'

	export default {
		components: {PrfTraceabilityView},
		data: () => ({
			showTraceability: false,
			selected: {},
			filter: {},
			config: {
				service: sTraceabilityService,
				model: {
					PltID: "ID",
					SecStatus: "",
					PltDate: "datetime",
					RcfDateCalibrated: "datetime",
					RcsDateHarvestSchedule: "date",
					RcfDateCalibratedInitial: "datetime",
					TnrDateBegin: "datetime",
					TrnDateEnd: "datetime",
					CocDateBegin: "datetime",
					CocDateEnd: "datetime",
				},
				headers: [
					{ text: "Paleta", value: "PltID" },
					{ text: "Procesadora", value: "TypeOriginName", width: 100 },
					{ text: "Pedido", value: "OrdID", width: 100 },
					{ text: "Cliente", value: "CardName", width: 300 },
					{ text: "Contenedor", value: "PlsNumberContainer", width: 100 },
					{ text: "PT", value: "ItemName", width: 500 },
					
					{ text: "Peso Palet", value: "PltWeightNet", width: 100 },
					{ text: "Fecha Prod.", value: "PltDate", width: 200 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 150 },
					{
						text: "Centro Produccion",
						value: "TypeDestinyPacking",
						width: 100,
					},
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 150 },
					{ text: "Variedad", value: "VrtName", width: 150 },
					{ text: "Calibre", value: "TypeCaliberName", width: 100 },
					{ text: "FBP", value: "PltFruitxBoxe", width: 100 },
					{
						text: "Cajas",
						value: "UwoMaterialListNumberBoxes",
						width: 100,
					},
					{ text: "Peso Cajas", value: "PltWeightNet", width: 100 },
					{ text: "Brix Min", value: "BrxMin", width: 100 },
					{ text: "Brix Max", value: "BrxMax", width: 100 },
					{ text: "Color", value: "BrxColor", width: 300 },
					{ text: "Lote Produccion", value: "RcfIDFormat" },
					{ text: "Jabas LP", value: "AmountJabasWeigh", width: 100 },
					{
						text: "Fecha Calibrado",
						value: "RcfDateCalibrated",
						width: 200,
					},
					{ text: "Codigo Fundo", value: "FltCode", width: 100 },
					{ text: "Alias", value: "FltAlias", width: 100 },
					{ text: "Fundo", value: "FagName", width: 300 },
					{ text: "Productor", value: "PrdCardName", width: 400 },
					{ text: "Zona", value: "ZonName", width: 250 },
					{
						text: "N° Guia Cosecha",
						value: "RcfNumberHarvestGuide",
						width: 150,
					},
					{
						text: "Fecha Cosecha",
						value: "RcsDateHarvestSchedule",
						width: 150,
					},
				],
			},
		}),

		methods: {
			rowSelected(item) {
				this.selected = item[0];
				console.log(this.selected);
			},
		},
	};
</script>
