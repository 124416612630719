import Service from "../Service";
import Vue from "vue";
const resource = "Traceability/"

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationEur", parameters, {
            params: { requestID: requestID },
        });
    },

    GetTraceabilityEur(obj, requestID) {
        return Service.post(resource + "GetTraceabilityEur", obj, {
            params: { requestID: requestID }
        });
    },
};