<template>
  <div>
        <v-card>
<v-container>
<v-row>
	<h2 class="mt-6 ml-6">Asistencia Tecnica - {{selected.FltCode}}</h2>
						<v-spacer> </v-spacer>
						<!-- <v-btn
							class="mt-3 mr-3"
							small
							fab
							color="default"
							><i style="font-size:20px;" color="error" class="fas fa-file-pdf"></i
						></v-btn> -->
						<v-btn
							@click="$emit('close')"
							class="mt-3 mr-3"
							small
							fab
							color="error"
							><i style="font-size:16px;" class="fas fa-times"></i
						></v-btn>
					</v-row>

					<v-row>
<v-col class="pb-0"><v-divider></v-divider>
</v-col></v-row>


<v-row>

<v-col lg="6" class="pt-0">
<v-list>
 <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Productor</v-list-item-title>
          <v-list-item-subtitle >
		  - {{selected.PrdCardName}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

        <v-list-item>
        <v-list-item-avatar>
<v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Direccion</v-list-item-title>
          <v-list-item-subtitle >{{selected.FagAddress}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


	   <v-list-item>
        <v-list-item-avatar>
<v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Capataces</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcfQuantityForeman}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	  <v-list-item>
        <v-list-item-avatar>
<v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Guia Cosecha</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcfNumberHarvestGuide}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	   <v-list-item>
        <v-list-item-avatar>
<v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Precinto Sunshine</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcfSunshineSeal}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	    <v-list-item>
        <v-list-item-avatar>
<v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Fecha Cosecha</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcsDateHarvestSchedule}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


	       


	     
    </v-list></v-col>
<v-col class="pt-0">
<v-list>
  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Fundo</v-list-item-title>
          <v-list-item-subtitle >{{selected.FagName}} - {{selected.FltCodSenasa}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

        <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Zona</v-list-item-title>
          <v-list-item-subtitle >{{selected.ZonName}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Cosechadores</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcfQuantityHarvester}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Guia Remision</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcfNumberReferralGuide}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Precinto senasa</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcfSenasaSeal}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Cantidad Visitas	</v-list-item-title>
          <v-list-item-subtitle >{{selected.VstMade}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	

	
 
</v-list>
</v-col>

<v-col cols="12">
    <v-data-table
													item-key="RcsGngID"
													dense
													:items="itemsGang"
													:headers="headersGang"
													:items-per-page="-1"
													hide-default-footer
													disable-sort
												></v-data-table>
</v-col>

<!-- <v-col cols="12">
    <v-data-table
													item-key=""
													dense
													:items="itemsProductsNotPermited"
													:headers="headersitemsProductsNotPermited"
													:items-per-page="-1"
													hide-default-footer
													disable-sort
												></v-data-table>
</v-col> -->

</v-row>

<v-row>
	<h2 class="mt-6 ml-6">Recepcion Frescos  - LP: {{selected.RcfIDFormat}} </h2>
						<v-spacer></v-spacer>
					
					</v-row>

					<v-row>
<v-col class="pb-0"><v-divider></v-divider>
</v-col></v-row>

<v-row>

<v-col lg="6" class="pt-0">
<v-list>

	 
	     <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Peso Guia (KG)</v-list-item-title>
          <v-list-item-subtitle >{{selected.RcfWeightGuide}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	   <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Peso Neto (KG)</v-list-item-title>
          <v-list-item-subtitle >{{selected.WbbNetWeight}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
	  <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Peso Promedio Jaba (KG)</v-list-item-title>
          <v-list-item-subtitle >{{selected.WbbWeightAverage}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

	   <v-list-item>
        <v-list-item-avatar>
<v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Brix (Min - Max)</v-list-item-title>
          <v-list-item-subtitle >{{selected.BrxMin}}- {{selected.BrxMax}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

<v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Turno Inicio</v-list-item-title>
     <v-list-item-subtitle >{{selected.TnrDateBegin}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>

	     
    </v-list></v-col>
<v-col class="pt-0">
<v-list>

	
	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Cant. Jabas Cosechadas</v-list-item-title>
     <v-list-item-subtitle >{{selected.AmountJabasWeigh}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>

	    <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Cant. Jabas Estimadas</v-list-item-title>
     <v-list-item-subtitle >{{selected.RcfQuantityEstimate}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>
	  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Alerta</v-list-item-title>
     <v-list-item-subtitle>{{selected.TypeAlertName}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>

	  	  <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Color</v-list-item-title>
     <v-list-item-subtitle >{{selected.BrxColor}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>

	   <v-list-item>
        <v-list-item-avatar>
     <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title >Turno Fin</v-list-item-title>
     <v-list-item-subtitle >{{selected.TrnDateEnd}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
      </v-list-item>


	

</v-list>
</v-col>

</v-row>

</v-container>
        </v-card>






        <v-card>
        <v-container>
        <v-row>
            <h2 class="mt-6 ml-6">Calibrado </h2>
                                <v-spacer> </v-spacer>
                            </v-row>

                            <v-row>
        <v-col class="pb-0"><v-divider></v-divider>
        </v-col></v-row>


        <v-row>

        <v-col lg="6" class="pt-0">
        <v-list>
        <v-list-item>
                <v-list-item-avatar>
            <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Fecha/Hora Calibrado</v-list-item-title>
                <v-list-item-subtitle >
                - {{selected.RcfDateCalibrated}} </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-avatar>
        <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Cantidad KG - Albaran</v-list-item-title>
                <v-list-item-subtitle >{{selected.AlbQuantityKg}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
                <v-list-item>
                <v-list-item-avatar>
        <v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Peso Exportable</v-list-item-title>
                <v-list-item-subtitle >{{selected.LdhWeightExportable}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>






                
            </v-list></v-col>


        <v-col class="pt-0">
        <v-list>
        <v-list-item>
                <v-list-item-avatar>
            <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Fecha / Hora Primer Abastecimiento</v-list-item-title>
                <v-list-item-subtitle >{{selected.RcfDateCalibratedInitial}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-avatar>
            <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >KG Total descarte</v-list-item-title>
                <v-list-item-subtitle >{{selected.LdhDiscartTotal}} - {{selected.LdhDiscartTotalPerce}} %</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-avatar>
            <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Cant. Fruta muestreada (UN.)</v-list-item-title>
            <v-list-item-subtitle >{{selected.LdhQuantityShows}}</v-list-item-subtitle>  </v-list-item-content>   </v-list-item-content>
            </v-list-item>



        </v-list>
        </v-col>

















        </v-row>

        <v-row>
            <h2 class="mt-6 ml-6">Empaque  - {{selected.ItemName}}</h2>
                                <v-spacer> </v-spacer>
                            
                            </v-row>

                            <v-row>
        <v-col class="pb-0"><v-divider></v-divider>
        </v-col></v-row>

        <v-row>

        <v-col lg="6" class="pt-0">
        <v-list>
        <v-list-item>
                <v-list-item-avatar>
            <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Pallet</v-list-item-title>
                <v-list-item-subtitle >
                - {{selected.PltIDFormat}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-avatar>
        <v-btn color="warning" fab><i style="font-size:18px" class="fas fa-user-clock"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Pedido</v-list-item-title>
                <v-list-item-subtitle >{{selected.OrdID}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
                <v-list-item>
                <v-list-item-avatar>
        <v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Cantidad Cajas / Fruta x Caja</v-list-item-title>
                <v-list-item-subtitle >{{selected.UwoMaterialListNumberBoxes}} / {{selected.PltFruitxBoxe}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

                <v-list-item>
                <v-list-item-avatar>
        <v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Numero Contenedor</v-list-item-title>
                <v-list-item-subtitle >{{selected.PlsNumberContainer}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-avatar>
        <v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Temperaturas Ambiente</v-list-item-title>
                <v-list-item-subtitle >{{selected.CcdTemperatureEnvironment}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-avatar>
        <v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Fecha/Hora entrada camara</v-list-item-title>
                <v-list-item-subtitle >{{selected.CocDateBegin}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>




                
            </v-list></v-col>
        <v-col class="pt-0">
        <v-list>
        <v-list-item>
                <v-list-item-avatar>
            <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Cliente</v-list-item-title>
                <v-list-item-subtitle >{{selected.CardName}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-avatar>
            <v-btn color="info" fab><i class="fas fa-clock" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Peso KG</v-list-item-title>
                <v-list-item-subtitle >{{selected.PltWeightNet}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-avatar>
            <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Fecha Empaque</v-list-item-title>
            <v-list-item-subtitle >{{selected.PltDate}}</v-list-item-subtitle>  </v-list-item-content>   
            </v-list-item>

            <v-list-item>
                <v-list-item-avatar>
            <v-btn color="warning" fab><i class="fas fa-percent" style="font-size:18px"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Calibre</v-list-item-title>
            <v-list-item-subtitle >{{selected.TypeCaliberName}} </v-list-item-subtitle>  </v-list-item-content>   
            </v-list-item>



            <v-list-item>
                <v-list-item-avatar>
        <v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Temperaturas Pupa</v-list-item-title>
                <v-list-item-subtitle >{{selected.CcdTemperaturePulpe}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

                <v-list-item>
                <v-list-item-avatar>
        <v-btn color="success" fab><i style="font-size:18px" class="fas fa-vial"></i></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title >Fecha/Hora salida de Camara</v-list-item-title>
                <v-list-item-subtitle >{{selected.CocDateEnd}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>


            

        </v-list>
        </v-col>

        </v-row>

        </v-container>
        </v-card>
  </div>
</template>

<script>
import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService";
import _sAlertHistoryService from "@/services/FreshProduction/AlertHistoryService";
	export default {
		props: {
			selected: null
		},
        data(){
            return {
                itemsProductsNotPermited: [],
                headersitemsProductsNotPermited: [],
                itemsGang: [],
                headersGang: [
					{ text: "Sub Lote", value: "FltName", width: 300 },
					{ text: "Capataz", value: "FrmName", width: 350 },
					{
						text: "Cant. Personal.",
						value: "FrmQuantityHarvester",
						width: 100,
					},
					{
						text: "Cant. Jabas Prog.",
						value: "FrmQuantityEstimate",
						width: 100,
					},
					{
						text: "Cant. Jabas Cosechadas",
						value: "FrmQuantityReal",
						width: 100,
					},
				],
            }
        },
        methods: {
            load(){
                _sReceptionFresh
							.GetGang(
								{ RcfID: this.selected.RcfID },
								this.$fun.getUserID(),
							)
							.then(r => {
								this.itemsGang = r.data;
							});





                /*  _sAlertHistoryService.suppliesnotrecommended(3457, '11/17/2021', this.$fun.getUserID())
                    .then((resp) => {
                        if(resp.status == 200){
                        this.itemsProductsNotPermited = resp.data;

                        console.log("productos no aplicados", this.itemsProductsNotPermited);
                        
                        }
                    }) */
            }
        },
        created(){
            this.load()
        }
	};
</script>

<style>
</style>