import Service from "../Service";

const resource = "alerthistory/"

export default {
    suppliesnotrecommended(FltID, Datep, requestID) {
        return Service.post(resource + "suppliesnotrecommended", {}, {
            params: { FltID: FltID, Date: Datep, requestID: requestID },
        });
    },

    save(alh, requestID) {
        return Service.post(resource + "save", alh, {
            params: { requestID }
        });
    }
}