<template>
	<v-row>
		<div class="col-md-12 col-sm-12">
			<v-toolbar dense>
				<v-toolbar-title
					>Pallet
					<v-chip style="margin: 0px" small :color="'info'">
						Scannerar QR
					</v-chip>
				</v-toolbar-title>
			</v-toolbar>
			<v-card dense>
				<v-row justify="center">
					<!-- <v-col cols="12" lg="8" md="8">
						<s-scanner-qr
							:config="configScann"
							autofocus
							return-object
							@returnObj="returnObj($event)"
							:readonly="false"
						></s-scanner-qr>
					</v-col> -->
					<v-col
								align="center"
								cols="12"
								lg="8"
								md="8"
							>
								<s-scanner-qr
									:config="configScann"
									autofocus
									return-object
									@onValue="onValue($event)"
									:readonly="false"
								></s-scanner-qr>
							</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<v-data-table
							item-key="PltID"
							dense
							:items-per-page="-1"
							:items="itemsDetail"
							:headers="headersDetail"
							hide-default-footer
							order-by
						>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card>
		</div>
	</v-row>
</template>

<script>
	/* import _sPackingService from "../../../services/FreshProduction/PackingService"; */
	import sTraceabilityService from "../../../services/FreshProduction/PrfTraceabilityService.js";

	export default {
		components: {},
		data: () => ({
			itemsDetail: [],
			headersDetail: [{text: "Procesadora", value: "TypeOriginName", width: 100},
					{text: "Pedido", value: "OrdID", width: 100},
					{text: "Cliente", value: "CardName", width:300 },
					{text: "Contenedor", value: "PlsNumberContainer", width: 100},
					{text: "PT", value: "ItemName", width: 500},
					{text: "Paleta", value: "PltIDFormat"},
					{text: "Peso Palet", value: "PltWeightNet", width: 100},
					{text: "Fecha Prod.", value: "PltDate", width: 200},
					{text: "Cultivo", value: "TypeCultiveName", width: 150},
					{text: "Centro Produccion", value: "TypeDestinyPacking", width: 100},
					{text: "Tipo Cultivo", value: "TypeCropName", width: 150},
					{text: "Variedad", value: "VrtName", width: 150},
					{text: "Calibre", value: "TypeCaliberName", width: 100},
					{text: "FBP", value: "PltFruitxBoxe", width: 100},
					{text: "Cajas", value: "UwoMaterialListNumberBoxes", width: 100},
					{text: "Peso Cajas", value: "PltWeightNet", width: 100},
					{text: "Brix Min", value: "BrxMin", width: 100},
					{text: "Brix Max", value: "BrxMax", width: 100},
					{text: "Color", value: "BrxColor", width: 300},
					{text: "Lote Produccion", value: "RcfIDFormat"},
					{text: "Jabas LP", value: "AmountJabasWeigh", width: 100},
					{text: "Fecha Calibrado", value: "RcfDateCalibrated", width: 200},
					{text: "Codigo Fundo", value: "FltCode", width: 100},
					{text: "Alias", value: "FltAlias", width: 100},
					{text: "Fundo", value: "FagName", width: 300},
					{text: "Productor", value: "PrdCardName", width: 400},
					{text: "Zona", value: "ZonName", width: 250},
					{text: "N° Guia Cosecha", value: "RcfNumberHarvestGuide", width: 150},
					{text: "Fecha Cosecha", value: "RcsDateHarvestSchedule", width: 150},],
			configScann: {}
		}),

		methods: {
			onValue(val) {
				sTraceabilityService
					.GetTraceabilityEur({ PltID: val }, this.$fun.getUserID())
					.then(resp => {
						if (resp.status == 200) {
							this.itemsDetail.push(resp.data);
							console.log(resp.data);
						}
					});
			}
		}
	};
</script>

<style></style>
